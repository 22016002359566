import React, { useEffect, useState } from 'react';
import './TabsComponent.css';
import DomesImg from '../../assets/image/geodesic-domes/main-geodesic-domes.png';
import Arrows from '../../assets/image/geodesic-domes/arrows.png';
import TableComponent from '../TableComponent/TableComponent';
import SliderOfProjects from '../SliderOfProjects/SliderOfProjects';
import { useLocation, useNavigate } from 'react-router-dom';
import TableComponentMobile from '../TableComponentMobile/TableComponentMobile';

const tabs = [
  { label: '5m', id: '5m' },
  { label: '6m', id: '6m' },
  { label: '7m', id: '7m' },
  { label: '10m', id: '10m' },
  { label: '12m', id: '12m' },
  { label: '14m', id: '14m' },
  { label: '16m', id: '16m' },
  { label: '21m', id: '21m' },
  { label: '30m', id: '30m' },
];

const domeParameters = {
  '5m': {
    domeDiameter: 'Dome diameter - 5m',
    domeHeight: 'Dome height - 2.99m',
    floorSpace: 'Floor space - 19.6sq.m',
    screenArea: 'Screen area - 30sq.m',
  },
  '6m': {
    domeDiameter: 'Dome diameter - 6m',
    domeHeight: 'Dome height - 3.59m',
    floorSpace: 'Floor space - 28sq.m',
    screenArea: 'Screen area - 47sq.m',
  },
  '7m': {
    domeDiameter: 'Dome diameter - 7m',
    domeHeight: 'Dome height - 4.19m',
    floorSpace: 'Floor space - 38sq.m',
    screenArea: 'Screen area - 56sq.m',
  },
  '10m': {
    domeDiameter: 'Dome diameter - 10m',
    domeHeight: 'Dome height - 5.0m',
    floorSpace: 'Floor space - 78sq.m',
    screenArea: 'Screen area - 111sq.m',
  },
  '12m': {
    domeDiameter: 'Dome diameter - 12m',
    domeHeight: 'Dome height - 6.0m',
    floorSpace: 'Floor space - 113sq.m',
    screenArea: 'Screen area - 185sq.m',
  },
};

const capacity = {
  '5m': {
    seating: '16 people',
    standing: '30 people',
    time: '3-4 hours',
    team: '2 people',
  },
  '6m': {
    seating: '24 people',
    standing: '40 people',
    time: '3-4 hours',
    team: '2 people',
  },
  '7m': {
    seating: '32 people',
    standing: '60 people',
    time: '3-4 hours',
    team: '2 people',
  },
  '10m': {
    seating: '90 people',
    standing: '115 people',
    time: '6-7 hours',
    team: '4-5 people',
  },
  '12m': {
    seating: '98 people',
    standing: '128 people',
    time: '6-7 hours',
    team: '4-5 people',
  },
};

const projectionSystem = {
  '5m': ['2-channel projector system', 'Mirror projector system'],
  '6m': ['2-channel projector system', 'Mirror projector system'],
  '7m': ['2-3 channel projector system', 'Mirror projector system'],
  '10m': ['6-8 channel projector system', 'Mirror projector system'],
  '12m': ['8-12 channel projector system', 'Mirror projector system'],
};

const domeParameter = {
  '5m': [
    'Frame material - aluminium',
    'Dome cover material - industrial blackout fire retardant polyester',
    'Decorative dome cover - customized full color printing',
  ],
  '6m': ['Frame material - aluminium'],
  '7m': ['Frame material - aluminium'],
  '10m': ['Frame material - aluminium or steel'],
  '12m': ['Frame material - aluminium or steel'],
};

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState('5m');
  const [visible, setVisible] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const navigate = useNavigate();

  const updateURLWithTab = (tabId) => {
    navigate(`?tab=${tabId}`);
    setActiveTab(tabId);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabFromUrl = params.get('tab');
    if (tabFromUrl && tabs.some((tab) => tab.id === tabFromUrl)) {
      setActiveTab(tabFromUrl);
    }
  }, [location.search]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleViewProjectsClick = () => {
    setSliderOpen(true);
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div className="tabs-component">
      <div className="tabs-component__container container">
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`tab ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => updateURLWithTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
          <button className="compare-button" onClick={toggleVisibility}>
            <img src={Arrows} alt="Arrows" className="compare-button__icon" />
            <p className="compare-button__text">Compare</p>
          </button>
        </div>
        <div className="content-area">
          <img className="static-image" src={DomesImg} alt="Static" />
          <div className="dynamic-content">
            <div className="dynamic-content__top">
              <h5 className="dynamic-content__subtitle main-title__subtitle">
                {`${activeTab} Geodesic Dome`}
              </h5>
              <button
                className="btn__view-projects"
                onClick={handleViewProjectsClick}
              >
                View projects
              </button>
              <SliderOfProjects
                isOpen={isSliderOpen}
                onClose={handleCloseSlider}
              />
            </div>
            <p className="tab-details-text">
              Perfect projection dome for mid-size entertainment areas
            </p>
            <div className="tab-details__content">
              <div className="tab-details__column">
                <p className="tab-details__subtitle mt-characteristics">
                  Dome Parameters
                </p>
                <ul className="tab-details__list">
                  <li>{domeParameters[activeTab]?.domeDiameter}</li>
                  <li>{domeParameters[activeTab]?.domeHeight}</li>
                  <li>{domeParameters[activeTab]?.floorSpace}</li>
                  <li>{domeParameters[activeTab]?.screenArea}</li>
                </ul>
              </div>
              <div className="tab-details__column">
                <p className="tab-details__subtitle mt-characteristics">
                  Dome Capacity
                </p>
                <ul className="tab-details__list">
                  <li>Seating capacity: {capacity[activeTab]?.seating}</li>
                  <li>Standing capacity: {capacity[activeTab]?.standing}</li>
                </ul>
                <p className="tab-details__subtitle mt-characteristics">
                  Dome Setup Time
                </p>
                <ul className="tab-details__list">
                  <li>Time: {capacity[activeTab]?.time}</li>
                  <li>Team: {capacity[activeTab]?.team}</li>
                </ul>
              </div>
              <div className="tab-details__column">
                <p className="tab-details__subtitle mt-characteristics">
                  Projection System
                </p>
                <ul className="tab-details__list">
                  {projectionSystem[activeTab]?.map((system, index) => (
                    <li key={index}>{system}</li>
                  ))}
                </ul>
              </div>
              <div className="tab-details__column">
                <p className="tab-details__subtitle mt-characteristics">
                  Dome Materials
                </p>
                <ul className="tab-details__list tab-details__list-parameters">
                  {domeParameter[activeTab]?.map((param, index) => (
                    <li key={index}>{param}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {visible &&
        (isMobile ? (
          <TableComponentMobile
            visible={visible}
            toggleVisibility={toggleVisibility}
          />
        ) : (
          <TableComponent
            visible={visible}
            toggleVisibility={toggleVisibility}
          />
        ))}
    </div>
  );
};

export default TabsComponent;
