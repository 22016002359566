import React, { useRef } from 'react';
import './ReviewsOfCustomers.css';
import ReviewsCard from '../UI/ReviewsCard/ReviewsCard';
import data from '../../assets/data/reviewsOfCustomers';
import AnimatedText from '../UI/AnimatedText';
import AnimatedCard from '../UI/AnimatedCard';

const ReviewsOfCustomers = () => {
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };
  return (
    <section className="reviews">
      <div className="reviews__container container">
        <AnimatedText>
          <h5 className="reviews__title">Reviews of our customers</h5>
        </AnimatedText>

        <ReviewsCard />
        <div className="reviews__circle1"></div>
        <div className="reviews__circle2"></div>
      </div>
    </section>
  );
};

export default ReviewsOfCustomers;
