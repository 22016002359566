import React from 'react';
import UpcomingEvent from '../components/UpcomingEvent/UpcomingEvent';
import Header from '../components/Header/Header';
import TabsComponent from '../components/TabsComponent/TabsComponent';
import VideoSection from '../components/VideoSection/VideoSection';

const GeodesicDomes = () => {
  return (
    <>
      <Header activePage="geodesic-domes" />
      <div className="geodesic-domes__page">
        <div className="geodesic-domes__container container">
          <h2 className="geodesic-domes__title main-title">Geodesic domes</h2>
          <h5 className="geodesic-domes__subtitle main-title__subtitle">
            Geodomes for events, trade fairs, concerts and festivals - for sale
            & hire
          </h5>
          <div className="geodesic-domes__texts-block">
            <p className="geodesic-domes__text-left ds__text-small">
              Portable frame domes, also known as Geodesic dome tents, are used
              primarily for immersive entertainment and may also be used as
              rooms for carrying out advertising campaigns, presentations,
              mobile restaurants, and conferences in the most picturesque
              corners of the country, a central square of a city or just about
              any place. The inner projection screen takes its shape from a
              vacuum created between the twin wall sealed dome.
            </p>
            <p className="geodesic-domes__text-right ds__text-small">
              We offer geodesic domes made of steel pipes and special PVC
              fabrics, as well as quick-assembly projection domes based on the
              aluminum frame, which are lightweight and extremely fast assembly,
              assembling a dome 5 m in diameter in 2 hours by one person.
            </p>
          </div>
        </div>

        <TabsComponent />
        <VideoSection />
        <UpcomingEvent />
      </div>
    </>
  );
};

export default GeodesicDomes;
