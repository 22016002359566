import mobileIcon1 from '../image/inflatable-planetarium/product-img1.svg';
import mobileIcon2 from '../image/inflatable-planetarium/product-img2.svg';
import mobileIcon3 from '../image/inflatable-planetarium/product-img3.svg';
import mobileIcon4 from '../image/inflatable-planetarium/product-img4.svg';
import mobileIcon5 from '../image/inflatable-planetarium/product-img5.svg';
import mobileIcon6 from '../image/inflatable-planetarium/product-img6.svg';
import mobileIcon7 from '../image/inflatable-planetarium/product-img7.svg';
import mobileIcon8 from '../image/inflatable-planetarium/product-img8.svg';
import mobileIcon9 from '../image/inflatable-planetarium/product-img9.svg';
import mobileIcon10 from '../image/inflatable-planetarium/product-img8.svg';
import mobileIcon11 from '../image/inflatable-planetarium/product-img9.svg';

const mobilePlanetariumData = [
  {
    id: 1,
    image: mobileIcon1,
    text: 'Constant air circulation from the blower to the spectators creates comfortable conditions.',
  },
  {
    id: 2,
    image: mobileIcon2,
    text: 'Three-layers of fabric with a density up to 300 g/m² completely blocks out the external light.',
  },
  {
    id: 3,
    image: mobileIcon3,
    text: 'Our domes are designed with the optimal quantity segments which provide the correct form for the screen.',
  },
  {
    id: 4,
    image: mobileIcon4,
    text: 'Our fabric is extremely durable and resistant to fracture, with strong mechanical loads and a microporous membrane.',
  },
  {
    id: 5,
    image: mobileIcon5,
    text: 'Air conditioning or heating systems for use in extreme climates optional.',
  },
  {
    id: 6,
    image: mobileIcon6,
    text: 'Easy system to regulate the intensity of the airflow from the dome.',
  },
  {
    id: 7,
    image: mobileIcon7,
    text: 'Customised designs of external shells optional.',
  },
  {
    id: 8,
    image: mobileIcon8,
    text: 'It provides durability, water resistance and fire resistance of the dome.',
  },
  {
    id: 9,
    image: mobileIcon9,
    text: 'Specially designed inner screen fabric provides the highest quality of the projection, contrast, color accuracy and uniformity of light.',
  },
  {
    id: 10,
    image: mobileIcon10,
    text: 'Offset joints between segments ensure maximum durability, making the seamless perceptible, further eliminating any light penetration at the seams.',
  },
  {
    id: 11,
    image: mobileIcon11,
    text: 'Our fabric has three layers and strengthened, thicker thread in increments of 2 to 10 mm, which creates an overlapping, solid grid making it more resistant to rupture along the grain.',
  },
];

export default mobilePlanetariumData;
