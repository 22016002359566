import React, { useRef, useState, useEffect } from 'react';
import Video from '../../assets/video/section-video.mp4';
import './VideoSection.css';
import play from '../../assets/image/play.png';
import stop from '../../assets/image/stop.png';

const VideoSection = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.loop = true;
      videoRef.current.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    }
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      setIsPlaying(false);
    } else {
      if (videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current.play().catch((error) => {
          console.error('Error playing video:', error);
        });
      }
      setIsPlaying(true);
    }
  };

  return (
    <div className="video-section">
      <video ref={videoRef} className="video">
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button
        className={`btn-play-video ${isPlaying ? 'stop-button' : ''}`}
        onClick={togglePlayPause}
      >
        {isPlaying ? 'Stop' : 'Play video'}
        <img
          src={isPlaying ? stop : play}
          alt={isPlaying ? 'Stop' : 'Play'}
          className="play-icon"
        />
      </button>
    </div>
  );
};

export default VideoSection;
