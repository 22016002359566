import React from 'react';
import { motion } from 'framer-motion';
import socialIcons from '../../../assets/data/socialIcons';
import './SocialRow.css';

const SocialRow = ({ itemClass = '', text = '' }) => {
  return (
    <div className="social-row__left">
      <ul className="hero__social">
        {socialIcons.map((icon, index) => (
          <motion.li
            key={index}
            whileHover={{ scale: 1.2 }}
            transition={{ duration: 0.3 }}
            className={`item__social ${itemClass}`}
          >
            <a href={icon.href} className="link__social">
              <img src={icon.src} alt={icon.alt} className="hero-icons" />
            </a>
          </motion.li>
        ))}
      </ul>
      {text && <p>{text}</p>}
    </div>
  );
};

export default SocialRow;
