import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import './DomeSwiper.css';
import { ReactComponent as Arrow } from '../../assets/image/inflatable-planetarium/arrow.svg';
import { slides1, slides2 } from '../../assets/data/slides'; // Імпортуємо слайди
import Button from '../UI/Button/Button';
import ScrollText from '../UI/ScrollText/ScrollText';

const DomeSwiper = ({ title, description, slides }) => {
  const swiperRef = useRef(null);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <div className="dome-swiper container swiper-row">
      <div className="description-container ">
        <h2 className="ds-text swiper-row__title">{title}</h2>
        <ScrollText>
          <p className="swiper-row__description">{description}</p>
        </ScrollText>
        <Button
          to="/path-to-your-page"
          buttonText="Receive best offer"
          className="swiper-row__btn"
        />
      </div>

      <div className="dome-image">
        <Swiper
          ref={swiperRef}
          spaceBetween={30}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
          onSlideChange={handleSlideChange}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <img
                src={slide.imageUrl}
                alt={`Dome ${index + 1}`}
                className="dome-slider__img"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="swiper-navigations">
          <div
            className={`swiper-button-prev swiper-btn-pr ${
              isStart ? 'disabled' : ''
            }`}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <Arrow className="arrow-right" />
          </div>
          <div
            className={`swiper-button-next swiper-btn-next ${
              isEnd ? 'disabled' : ''
            }`}
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <Arrow className="arrow-left" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomeSwiper;
