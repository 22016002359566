import React from 'react';
import './TableComponent.css';

const TableComponent = ({ visible, toggleVisibility }) => {
  return (
    <div className={`table-section ${visible ? 'show' : ''}`}>
      <div className="table__container container">
        <div className="table-section__top">
          <h2 className="table-title">Approximate capacity of the domes</h2>
          <button
            className="close-btn close-btn-for-table"
            onClick={toggleVisibility}
          >
            <span className="close-btn__icon"></span>
          </button>
        </div>

        <table className="custom-table">
          <tbody className="box-table">
            <tr className="table-row-parameters">
              <td className="title-row">Base Diameter (m)</td>
              <td>5m</td>
              <td>6m</td>
              <td>7m</td>
              <td>8m</td>
              <td>10m</td>
              <td>12m</td>
              <td>14m</td>
              <td>16m</td>
              <td>21m</td>
              <td>30m</td>
            </tr>
            <tr>
              <td className="title-row">Height</td>
              <td>2.99</td>
              <td>3.59</td>
              <td>4.0</td>
              <td>4.19</td>
              <td>5.0</td>
              <td>6.0</td>
              <td>6.25</td>
              <td>7.2</td>
              <td>10.5</td>
              <td>15</td>
            </tr>
            <tr>
              <td className="title-row">Surface Area (m2)</td>
              <td>19.6</td>
              <td>28</td>
              <td>38</td>
              <td>50</td>
              <td>78</td>
              <td>113</td>
              <td>153</td>
              <td>200</td>
              <td>346</td>
              <td>706</td>
            </tr>
            <tr>
              <td className="title-row">People standing (max)</td>
              <td>25</td>
              <td>40</td>
              <td>45</td>
              <td>55</td>
              <td>100</td>
              <td>140</td>
              <td>190</td>
              <td>220</td>
              <td>420</td>
              <td>1400</td>
            </tr>
            <tr>
              <td>People sitting (max)</td>
              <td>15</td>
              <td>25</td>
              <td>32</td>
              <td>35</td>
              <td>48</td>
              <td>79</td>
              <td>103</td>
              <td>110</td>
              <td>215</td>
              <td>790</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
