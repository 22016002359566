import domeImg1 from '../image/dome-models/dome-img1.jpg';
import domeImg2 from '../image/dome-models/dome-img2.jpg';
import domeImg3 from '../image/dome-models/dome-img3.jpg';
import domeImg4 from '../image/dome-models/dome-img4.jpg';

const imageCardsData = [
  {
    id: 1,
    imgSrc: domeImg4,
    title: 'Sota Dome',
    description: 'Portable planetarium<br />for indoor use',
    link: '/sota-dome',
  },

  {
    id: 2,
    imgSrc: domeImg2,
    title: 'Geodesic Domes',
    description: 'Portable planetarium<br />for indoor use',
    link: '/geodesic-domes',
  },
  {
    id: 3,
    imgSrc: domeImg3,
    title: 'Air Bubble Domes',
    description: 'Portable planetarium<br />for indoor use',
    link: '/air-bubble-domes',
  },
  {
    id: 4,
    imgSrc: domeImg1,
    title: 'Inflatable Dome',
    description: 'Portable planetarium<br />for indoor use',
    link: '/inflatable-dome',
  },
];
export default imageCardsData;
