import ShiraImg1 from '../image/shira-softwares/shira-product1.jpg';
import ShiraImg2 from '../image/shira-softwares/shira-product2.jpg';
import ShiraImg3 from '../image/shira-softwares/shira-product3.jpg';
import ShiraImg4 from '../image/shira-softwares/shira-product4.jpg';

const shiraProductData = [
  {
    id: 1,
    subtitle: 'Shira Player',
    description:
      'Now it is easy to show flat movies and images presentation on dome as warped with "Flat Media Presentation" specification of Shira Player.',
    imageSrc: ShiraImg1,
    imageAlt: 'Shira Player',
    to: '/',
  },
  {
    id: 2,
    subtitle: 'Shira Warper',
    description:
      'The Shira Warper vividly converts fisheye content from the source screen center into the target screen as a spherical mirror distortion.',
    imageSrc: ShiraImg2,
    imageAlt: 'Shira Warper',
    to: '/',
  },
  {
    id: 3,
    subtitle: 'Shira Universe',
    description:
      'Real-Time 3D Space Flight experience with Planetarium Presentation. The main improvements made in this version are as follows',
    imageSrc: ShiraImg3,
    imageAlt: 'Shira Universe',
    to: '/',
  },
  {
    id: 4,
    subtitle: 'Shira Presenter',
    description:
      '8K fulldome media contents can play from disk or directly from the internet is a media player.',
    imageSrc: ShiraImg4,
    imageAlt: 'Shira Presenter',
    to: '/',
  },
];

export default shiraProductData;
