import React from 'react';
import './ProjectionSystems.css';
import { NavLink } from 'react-router-dom';
import AnimatedText from '../UI/AnimatedText';

const ProjectionSystems = () => {
  return (
    <section className="systems">
      <div className="systems__container container">
        <div className="systems__content content-box">
          <div className="content-box__left">
            <AnimatedText>
              <h4 className="systems__title">Projection systems</h4>
              <NavLink to="/our-projects" className="btn__projections">
                See all projection systems
              </NavLink>
            </AnimatedText>
          </div>

          <div className="content-box__right">
            <AnimatedText>
              <p className="content-box__right-text">
                We provide spherical mirror projection systems superior to the
                more expensive fisheye projection systems and partner with
                industry production specialists to provide bespoke
                multi-projector systems for larger scale applications. Vide o
                content is delivered to the projector wirelessly using Aypad.
              </p>
            </AnimatedText>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectionSystems;
