import SliderImg1 from '../image/inflatable-planetarium/slider-img1.jpg';
import SliderImg2 from '../image/inflatable-planetarium/slider-img2.jpeg';
import SliderImg3 from '../image/inflatable-planetarium/slider-img3.jpg';
import SliderImg4 from '../image/inflatable-planetarium/slider-img4.jpg';

export const slides1 = [
  {
    imageUrl: SliderImg1,
  },
  {
    imageUrl: SliderImg2,
  },
];

export const slides2 = [
  {
    imageUrl: SliderImg3,
  },
  {
    imageUrl: SliderImg2,
  },
];
export const slides3 = [
  {
    imageUrl: SliderImg4,
  },
  {
    imageUrl: SliderImg2,
  },
];
