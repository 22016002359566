import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Hero.css';
import Message from '../../assets/image/message.svg';
import SliderEffects from '../UI/SliderEffects/SliderEffects';
import SocialRow from '../UI/SocialRow/SocialRow';

const Hero = () => {
  const controls = useAnimation();
  const [scrollY, setScrollY] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 50) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: -20 });
    }
  }, [scrollY, controls]);

  const titleText = 'Taking any event to the whole new level.';

  return (
    <section className="hero">
      <div className="hero__container container">
        <motion.h1
          className="hero__title gradient-text"
          initial={{ opacity: 0, y: -20 }}
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          {titleText.split('').map((char, index) => (
            <motion.span
              key={index}
              className="letter"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {char}
            </motion.span>
          ))}
        </motion.h1>

        <motion.p
          className="hero__text"
          initial={{ opacity: 0, y: -20 }}
          animate={controls}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Scroll to explore
        </motion.p>
      </div>

      <motion.div
        className="hero-swiper"
        initial={{ x: '100%', opacity: 0 }}
        animate={{
          x:
            scrollY > 50
              ? windowWidth > 1200
                ? '80%'
                : windowWidth > 768
                ? '80%'
                : windowWidth > 500
                ? '76%'
                : windowWidth > 480
                ? '72%'
                : windowWidth > 360
                ? '68%'
                : '64%'
              : '100%',
          opacity: scrollY > 50 ? 1 : 0,
        }}
        transition={{ duration: 2 }}
      >
        <SliderEffects />
      </motion.div>

      <div className="social-row">
        <SocialRow text="Follow us on Social platform" />
        <div className="social-row__right"></div>
      </div>
    </section>
  );
};

export default Hero;
