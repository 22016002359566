import React, { useState, useEffect, useRef } from 'react';
import './ReviewsCard.css';
import reviewsOfCustomers from '../../../assets/data/reviewsOfCustomers';
import { NavLink } from 'react-router-dom';
import Arrow from '../Arrow';
import AnimatedCard from '../AnimatedCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const ReviewsCard = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [groupedReviews, setGroupedReviews] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const swiperRef = useRef(null); // Create a ref for Swiper

  // Track window size changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update slides per view based on window width
  useEffect(() => {
    if (windowWidth <= 360) {
      setSlidesPerView(1);
    } else if (windowWidth <= 480) {
      setSlidesPerView(1);
    } else if (windowWidth <= 768) {
      setSlidesPerView(2);
    } else if (windowWidth <= 1024) {
      setSlidesPerView(2);
    } else if (windowWidth <= 1200) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(3);
    }
  }, [windowWidth]);

  // Group reviews based on slides per view
  useEffect(() => {
    const newGroupedReviews = [];
    const itemsPerGroup = slidesPerView === 3 ? 2 : 1; // Adjust grouping logic based on slides per view

    for (let i = 0; i < reviewsOfCustomers.length; i += itemsPerGroup) {
      newGroupedReviews.push(reviewsOfCustomers.slice(i, i + itemsPerGroup));
    }
    setGroupedReviews(newGroupedReviews);
  }, [slidesPerView]);

  const handleSwipe = (index) => {
    setActiveSlide(index);
  };

  // Reset Swiper to the first slide when it initializes
  const handleSwiperInit = (swiper) => {
    swiperRef.current = swiper; // Store the Swiper instance
    swiper.slideTo(0); // Reset to the first slide
  };

  return (
    <div className="reviews__wrapper">
      {windowWidth < 1600 ? (
        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          onSlideChange={(swiper) => handleSwipe(swiper.activeIndex)}
          onSwiper={handleSwiperInit}
          breakpoints={{
            360: { slidesPerView: 1 },
            480: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            1024: { slidesPerView: 2 },
            1200: { slidesPerView: 2 },
          }}
        >
          {groupedReviews.map((group, groupIndex) => (
            <SwiperSlide key={groupIndex} className="reviews-swiper-slide">
              <AnimatedCard>
                {group.map(
                  ({ id, imgSrc, userName, category, description, path }) => (
                    <div
                      key={id}
                      className={`review-card ${
                        activeSlide === groupIndex ? 'active' : ''
                      }`}
                    >
                      <div className="review-card__top">
                        <img
                          src={imgSrc}
                          alt={userName}
                          className="review-card__img"
                        />
                        <div className="review-card__info">
                          <p className="review-card__name">{userName}</p>
                          <span className="category__line">
                            <NavLink
                              to={path}
                              className="review-card__category"
                            >
                              {category}
                            </NavLink>
                            <Arrow className="review-card__arrows" />
                          </span>
                        </div>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{ __html: description }}
                        className="review-card__description"
                      />
                    </div>
                  )
                )}
              </AnimatedCard>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        groupedReviews.map((group, groupIndex) => (
          <AnimatedCard key={groupIndex}>
            <div className="reviews__group">
              {group.map(
                (
                  { id, imgSrc, userName, category, description, path },
                  index
                ) => (
                  <div
                    key={id}
                    className={`review-card ${
                      activeSlide === index ? 'active' : ''
                    }`}
                  >
                    <div className="review-card__top">
                      <img
                        src={imgSrc}
                        alt={userName}
                        className="review-card__img"
                      />
                      <div className="review-card__info">
                        <p className="review-card__name">{userName}</p>
                        <span className="category__line">
                          <NavLink to={path} className="review-card__category">
                            {category}
                          </NavLink>
                          <Arrow className="review-card__arrows" />
                        </span>
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: description }}
                      className="review-card__description"
                    />
                  </div>
                )
              )}
            </div>
          </AnimatedCard>
        ))
      )}

      <div className="reviews__pagination">
        {groupedReviews.map((_, index) => (
          <div
            key={index}
            className={`pagination-dot ${
              activeSlide === index ? 'active' : ''
            }`}
            onClick={() => handleSwipe(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ReviewsCard;
