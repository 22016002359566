import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './MobileMenu.css';
import AnimatedCard from '../UI/AnimatedCard';
import SocialRow from '../UI/SocialRow/SocialRow';
import LanguageSwitcher from '../UI/LanguageSwitcher/LanguageSwitcher';
import Button from '../UI/Button/Button';

const menuLinks = [
  { path: '/inflatable-dome', display: 'Inflatable Dome' },
  { path: '/air-bubble-domes', display: 'Air bubble Domes' },
  { path: '/geodesic-domes', display: 'Geodesic domes' },
  { path: '/sota-dome', display: 'Sota Dome' },
  { path: '/projection-dome-system', display: 'Projection dome system' },
  { path: '/planetarium-softwares', display: 'Planetarium Software' },
  { path: '/domes-for-rent', display: 'Projection dome Rental' },
  { path: '/', display: 'Planetarium' },
  { path: '/our-projects', display: 'Our projects' },
];

const MobileMenu = ({ menuOpen, toggleMenu, to }) => {
  const [activeLanguage, setActiveLanguage] = useState('en');
  return (
    <>
      {menuOpen && (
        <motion.div
          className="mobile-menu"
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ duration: 0.2 }}
        >
          <ul className="mobile-menu-links">
            <li className="mobile-menu__item">
              <p className="mobile-menu__title">Menu</p>
            </li>
            {menuLinks.map((link, index) => (
              <li key={index} className="mobile-menu__item">
                <NavLink
                  to={link.path}
                  className="mobile-menu__link"
                  onClick={toggleMenu}
                >
                  {link.display}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="mobile-menu-social">
            <SocialRow itemClass="mobile-social-item" />
          </div>

          <div className="mobile__bottom">
            <div className="mobile-btn-contact">
              <Button
                to="/contacts"
                buttonText="Contacts"
                className="mobile-btn"
              />
            </div>
            <div className="mobile-menu-language">
              <LanguageSwitcher
                activeLanguage={activeLanguage}
                setActiveLanguage={setActiveLanguage}
                className="mobile-language"
              />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default MobileMenu;
