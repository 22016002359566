import React from 'react';
import imageCardsData from '../../../assets/data/imageCardsData';
import './ImageCard.css';
import AnimatedText from '../AnimatedText';

const ImageCard = ({ imgSrc, title, description, index, link }) => {
  return (
    <div className="card" key={index}>
      <a href={link} rel="noopener noreferrer" className="card__link">
        <img src={imgSrc} alt={title} className="card__image" />
      </a>
      <div className="card__content">
        <AnimatedText>
          <h4 className="card__title">{title}</h4>
          <p
            className="card__description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </AnimatedText>
      </div>
    </div>
  );
};

export default ImageCard;
