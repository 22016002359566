import React, { useState, useRef, useEffect } from 'react';
import './ImmersiveProjection.css';
import videoSrc from '../../assets/video/video.mp4';
import play from '../../assets/image/play.png';
import stop from '../../assets/image/stop.png';
import AnimatedText from '../UI/AnimatedText';

const ImmersiveProjection = () => {
  const [expanded, setExpanded] = useState(false);
  const videoRef = useRef(null);
  const projectionVideoRef = useRef(null);
  const descriptionRef = useRef(null);
  const scrollY = useRef(0);
  const loopIntervalRef = useRef(null);
  const [translateY, setTranslateY] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [closing, setClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            setTimeout(() => {
              setExpanded(true);
            }, 1000);
          } else {
            setExpanded(false);
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (projectionVideoRef.current) {
      observer.observe(projectionVideoRef.current);
    }

    return () => {
      if (projectionVideoRef.current) {
        observer.unobserve(projectionVideoRef.current);
      }
    };
  }, []);

  const playShortLoop = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => console.error('Error:', error));
      videoRef.current.muted = true;

      const loopTime = 10;

      loopIntervalRef.current = setInterval(() => {
        if (!expanded) {
          videoRef.current.currentTime = 0;
          videoRef.current
            .play()
            .catch((error) => console.error('Error:', error));
        }
      }, loopTime * 1000);
    }
  };

  useEffect(() => {
    if (expanded) {
      videoRef.current?.play().catch((error) => console.error('Error:', error));
      videoRef.current.muted = true;
      clearInterval(loopIntervalRef.current);
    } else {
      videoRef.current?.pause();
      playShortLoop();
    }

    return () => clearInterval(loopIntervalRef.current);
  }, [expanded]);

  const handleScroll = () => {
    scrollY.current = window.scrollY;

    if (descriptionRef.current && expanded && !closing) {
      const { top, height } = descriptionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const buttonHeight = 50;
      const textScrollPosition = Math.max(
        0,
        Math.min((windowHeight - top - buttonHeight) / 2, height)
      );
      const newTranslateY = -textScrollPosition;

      if (Math.abs(newTranslateY - translateY) > 1) {
        setTranslateY(newTranslateY);
      }

      const videoRect = projectionVideoRef.current.getBoundingClientRect();
      const isTextOverVideo =
        top < videoRect.bottom && top + height > videoRect.top;

      if (descriptionRef.current) {
        descriptionRef.current.classList.toggle('text-white', isTextOverVideo);
        descriptionRef.current.style.paddingBottom = `${Math.max(
          0,
          initialHeight - textScrollPosition
        )}px`;
      }
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  useEffect(() => {
    const handleScrollDebounced = debounce(handleScroll, 100);
    window.addEventListener('scroll', handleScrollDebounced);
    return () => window.removeEventListener('scroll', handleScrollDebounced);
  }, [expanded, closing]);

  useEffect(() => {
    if (descriptionRef.current) {
      setInitialHeight(descriptionRef.current.offsetHeight);
    }
  }, [descriptionRef]);

  const toggleExpand = () => {
    if (expanded) {
      setClosing(true);
      videoRef.current?.pause();

      setTimeout(() => {
        setExpanded(false);
        setClosing(false);
      }, 500);
    } else {
      setExpanded(true);
    }
  };

  return (
    <section
      className={`immersive-projection projection ${
        expanded ? 'expanded' : ''
      }`}
    >
      <div className="projection__container container">
        <div className="projection__wrapper">
          <div className={`projection__content ${expanded ? 'expanded' : ''}`}>
            <AnimatedText>
              <h3 className="projection__title">Immersive Projection Dome</h3>
            </AnimatedText>
            <AnimatedText>
              <div className="projection__text">
                Our domes are extremely portable with inflatable domes assembled
                in under 15 minutes and larger fixed frame domes from 2 hours.
              </div>
            </AnimatedText>
          </div>
          <div
            ref={projectionVideoRef}
            className={`projection__video ${expanded ? 'expanded' : ''} ${
              expanded ? 'relative' : 'absolute'
            }`}
          >
            <video ref={videoRef} autoPlay loop playsInline muted>
              <source src={videoSrc} type="video/mp4" />
            </video>
            <button
              className={`btn-play ${expanded ? 'stop-button' : ''}`}
              onClick={toggleExpand}
            >
              {expanded ? 'Stop' : 'Play video'}
              <img
                src={expanded ? stop : play}
                alt={expanded ? 'Stop' : 'Play'}
                className="play-icon"
              />
            </button>
          </div>
          <div
            className="projection__description"
            ref={descriptionRef}
            style={{
              transform: `translateY(${translateY}px)`,
              transition: `transform 0.5s ease, opacity 0.5s ease`,
              opacity: closing ? 0 : 1,
              marginBottom: closing ? '5px' : '10px',
              paddingBottom: '10px',
            }}
          >
            {expanded && (
              <>
                <div
                  className={`description-content ${closing ? 'closing' : ''}`}
                  style={{
                    transition: closing
                      ? 'opacity 0.5s ease'
                      : 'opacity 0.5s ease',
                  }}
                >
                  ASE produces Mobile Planetarium and other larger scale
                  Fulldome entertainment applications. Fulldome immersive
                  technology engages the peripheral vision which is credited
                  with depth perception similar to 3D stereoscopic effects
                  providing an educational and entertaining experience that is
                  far more engaging than traditional media.
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImmersiveProjection;
