import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      welcome: 'Welcome to our website',
      language: 'Language',
    },
  },
  ua: {
    translation: {
      welcome: 'Ласкаво просимо на наш сайт',
      language: 'Мова',
    },
  },
  pl: {
    translation: {
      welcome: 'Witamy na naszej stronie',
      language: 'Język',
    },
  },
};

i18n
  .use(initReactI18next) // Інтеграція з React
  .init({
    resources,
    lng: 'en', // Мова за замовчуванням
    interpolation: {
      escapeValue: false, // Реакція не потребує escape для безпеки
    },
  });

export default i18n;
