export const defaultNavLinks = [
  { path: '/', display: 'Planetarium' },
  { path: '/domes-for-rent', display: 'Domes for rent' },
  { path: '/our-projects', display: 'Projects' },
  { path: '/projection-dome-system', display: 'Projection systems' },
  { path: '/planetarium-softwares', display: 'Software' },
];

export const othersNavLinks = [
  { path: '/air-bubble-domes', display: 'Air bubble Domes' },
  { path: '/inflatable-dome', display: 'Inflatable Dome' },
  { path: '/geodesic-domes', display: 'Geodesic domes' },
  { path: '/sota-dome', display: 'Sota Dome' },
];
