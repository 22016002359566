import React from 'react';
import Header from '../components/Header/Header';
import ProductsCart from '../components/UI/ProductsCart/ProductsCart';
import Title from '../components/UI/Title/Title';
import ProductImg1 from '../assets/image/projection-system/product-img1.jpg';
import ProductImg2 from '../assets/image/projection-system/product-img2.jpg';
import VideoSection from '../components/VideoSection/VideoSection';
import UpcomingEvent from '../components/UpcomingEvent/UpcomingEvent';

const ProjectionDomeSystem = () => {
  return (
    <>
      <Header activePage="projection-dome-system" />
      <section className="projection-system">
        <div className="projection-system__container container">
          <Title
            text="Projection dome system"
            className="projection-system__title"
          />
          <h5 className="projection-system__subtitle main-title__subtitle">
            Projection systems for planetariums and full dome theaters.
          </h5>
          <p className="projection-system__text ds__text-small">
            We provide spherical mirror projection systems superior to the more
            expensive fisheye projection systems and partner with industry
            production specialists to provide bespoke multi-projector systems
            for larger scale applications. Video content is delivered to the
            projector wirelessly using Aypad.
          </p>
          <div className="projection-system__wrapper">
            <ProductsCart
              productsCartClass="product-system__card"
              title="Spherical mirror projection system"
              subtitle="Spherical mirror projection system key features:"
              description={
                <>
                  Significantly cheaper than fisheye lens systems Located away
                  from the center of the dome, the optimal viewing position
                  Allows a choice of projectors, unlike a fisheye lens system
                  Coverage of the dome can be controlled All pixels are used,
                  unlike the fisheye lens system No chromatic distortion, unlike
                  the fisheye lens system The universal solution can work with
                  any projector High-quality media player provided, laptop and
                  tablet interface optional Most affordable solution for
                  full-dome projection for the required levels of quality
                </>
              }
              buttonText="View the Setup Guide"
              imageSrc={ProductImg1}
              imageAlt="Stellarium Software"
              to="/"
              titleClass="projection-system__product-title"
              descriptionClass="projection-system__product-description"
              subtitleClass="projection-system__product-subtitle"
              buttonClass="projection-system__product-btn-none"
              imageClass="projection-system__product-img"
              productsCartContentClass="projection-system__product-content"
            />
            <ProductsCart
              productsCartClass="product-system__card"
              title="Multi-channels projection systems"
              subtitle="Multi-channels projection system key features:"
              description={
                <>
                  Full auto calibration with fisheye camera Multi-projection
                  Mapping technology For fixed domes with a diameter greater
                  than 7 m Up to 64 projectors, provides an incredibly vivid and
                  realistic picture Powerful servers, unique program setup and
                  calibration of images to create breathtaking visuals in 20
                  minutes Partnered with leading industry specialists and
                  studios
                </>
              }
              buttonText="View the Setup Guide"
              imageSrc={ProductImg2}
              imageAlt="Stellarium Software"
              to="/"
              titleClass="projection-system__product-title"
              subtitleClass="projection-system__product-subtitle"
              descriptionClass="projection-system__product-description-small"
              imageClass="projection-system__product-img projection-system__product-img--small"
              productsCartContentClass="projection-system__product-content"
              buttonClass="projection-system__product-btn"
            />
          </div>
        </div>
      </section>
      <VideoSection />
      <UpcomingEvent />
    </>
  );
};

export default ProjectionDomeSystem;
