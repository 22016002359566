import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Header.css';
import i18n from '../../i18n';
import EffectHover from '../EffectHover/EffectHover';
import Logo from '../UI/Logo';
import MobileMenu from '../MobileMenu/MobileMenu';
import { defaultNavLinks, othersNavLinks } from '../../assets/data/navLink';
import LanguageSwitcher from '../UI/LanguageSwitcher/LanguageSwitcher';

const Header = ({ color, activeClassName = 'nav__active' }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navLinks, setNavLinks] = useState(defaultNavLinks);
  const [activeLanguage, setActiveLanguage] = useState('en');
  const location = useLocation();

  const isInOthersNavLinks = () => {
    return othersNavLinks.some((link) =>
      location.pathname.startsWith(link.path)
    );
  };

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [menuOpen]);

  useEffect(() => {
    if (isInOthersNavLinks()) {
      setNavLinks(othersNavLinks);
    } else {
      setNavLinks(defaultNavLinks);
    }
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="nav__wrapper">
        <a href="/" className="logo">
          <Logo color={color} />
        </a>
        <div className="navigation">
          <ul className="menu">
            {navLinks.map((item, index) => (
              <li className="nav__item" key={index}>
                <div className="effect-hover">
                  <div className="dropdown-bg"></div>
                  <EffectHover />
                </div>
                <NavLink
                  to={item.path}
                  className={`${
                    location.pathname === item.path ? activeClassName : ''
                  } nav__link`}
                  onClick={(e) => {
                    if (location.pathname === item.path) {
                      e.preventDefault();
                    }
                  }}
                >
                  {item.display}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="nav__right">
          <div className="nav__item menu-contacts">
            <NavLink
              to="/contacts"
              className={`${
                location.pathname === '/contacts' ? activeClassName : ''
              } nav__link`}
              onClick={(e) => {
                if (location.pathname === '/contacts') {
                  e.preventDefault();
                }
              }}
            >
              Contacts
            </NavLink>
          </div>

          <LanguageSwitcher
            activeLanguage={activeLanguage}
            setActiveLanguage={setActiveLanguage}
          />

          <button className="nav__link menu-toggle-btn" onClick={toggleMenu}>
            {menuOpen ? 'Close' : 'Menu'}
          </button>

          {menuOpen && (
            <MobileMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
