import React from 'react';
import './ShiraProduct.css';
import Button from '../Button/Button';
import shiraProductData from '../../../assets/data/shiraProduct';
import AnimatedCard from '../AnimatedCard';

const ShiraProduct = ({ subtitle, description, imageSrc, imageAlt, to }) => {
  return (
    <AnimatedCard>
      <div className="shira-product__cart">
        <div className="shira-product__image">
          <img src={imageSrc} alt={imageAlt} />
        </div>
        <div className="shira-product__content">
          <div className="shira-product__subtitle main-title__subtitle">
            {subtitle}
          </div>
          <p className="shira-product__description ds__text-small">
            {description}
          </p>

          <Button
            to={to}
            buttonText="Learn more"
            className="shira-product__btn"
          />
        </div>
      </div>
    </AnimatedCard>
  );
};

export default ShiraProduct;
