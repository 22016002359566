import React from 'react';

import Header from '../components/Header/Header';
import Title from '../components/UI/Title/Title';
import ShiraProduct from '../components/UI/ShiraProduct/ShiraProduct';
import shiraProductData from '../assets/data/shiraProduct';

const ShiraSoftwares = () => {
  const oddCards = shiraProductData.filter((product) => product.id % 2 !== 0); // Непарні id
  const evenCards = shiraProductData.filter((product) => product.id % 2 === 0); // Парні id
  return (
    <>
      <Header activePage="/planetarium/shira-softwares" />
      <section className="shira-softwares">
        <div className="shira-softwares__container container">
          <Title text="Shira Softwares" className="shira-softwares__title" />
          <div className="shira-softwares__wrapper">
            <div className="shira-softwares__column">
              {oddCards.map((product, index) => (
                <ShiraProduct
                  key={index}
                  subtitle={product.subtitle}
                  description={product.description}
                  imageSrc={product.imageSrc}
                  imageAlt={product.imageAlt}
                  to={product.to}
                />
              ))}
            </div>
            <div className="shira-softwares__column shira-softwares__column--right">
              {evenCards.map((product, index) => (
                <ShiraProduct
                  key={index}
                  subtitle={product.subtitle}
                  description={product.description}
                  imageSrc={product.imageSrc}
                  imageAlt={product.imageAlt}
                  to={product.to}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShiraSoftwares;
