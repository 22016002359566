import React from 'react';

const Arrow = ({ className }) => {
  return (
    <svg
      width="15.718750"
      height="15.718750"
      viewBox="0 0 15.7188 15.7188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M13.21 4.25L2.09 15.37C1.86 15.6 1.57 15.71 1.21 15.71C0.86 15.71 0.57 15.6 0.34 15.37C0.11 15.14 0 14.85 0 14.5C0 14.14 0.11 13.85 0.34 13.62L11.46 2.5L1.96 2.5C1.61 2.5 1.31 2.38 1.07 2.14C0.83 1.9 0.71 1.6 0.71 1.25C0.71 0.89 0.83 0.59 1.07 0.35C1.31 0.11 1.61 0 1.96 0L14.46 0C14.82 0 15.11 0.11 15.35 0.35C15.59 0.59 15.71 0.89 15.71 1.25L15.71 13.75C15.71 14.1 15.59 14.4 15.35 14.64C15.11 14.88 14.82 15 14.46 15C14.11 15 13.81 14.88 13.57 14.64C13.33 14.4 13.21 14.1 13.21 13.75L13.21 4.25Z"
        fill="#7C6BFE"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </svg>
  );
};

export default Arrow;
