import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './ProductCartWithVideo.css';

const ProductsCartWithVideo = ({
  title,
  subtitle,
  description,
  buttonText,
  videoUrl, // URL для відео
  to,
  titleClass,
  subtitleClass,
  descriptionClass,
  buttonClass,
  videoClass,
  productsCartClass,
  productsCartContentClass,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        const videoIframe = videoRef.current;
        const videoId = videoUrl.split('/').pop();
        videoIframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&playlist=${videoId}`; // Автовідтворення відео

        const stopVideo = () => {
          videoIframe.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
        };
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [videoUrl]);

  return (
    <div className={`products-cart ${productsCartClass || ''}`}>
      <div
        className={`products-cart__content ${productsCartContentClass || ''}`}
      >
        <h6 className={`products-cart__title ${titleClass || ''}`}>{title}</h6>

        <div
          className={`products-cart__subtitle main-title__subtitle ${
            subtitleClass || ''
          }`}
        >
          {subtitle}
        </div>

        <div className={`products-cart__video ${videoClass || ''}`}>
          <iframe
            ref={videoRef}
            width="100%"
            height="100%"
            src={`${videoUrl}?autoplay=1&mute=1&loop=1&playlist=${videoUrl
              .split('/')
              .pop()}`}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <p
          className={`products-cart__description ds__text-small ${
            descriptionClass || ''
          }`}
        >
          {description}
        </p>

        <NavLink to={to} className={`products-cart__btn ${buttonClass || ''}`}>
          {buttonText}
        </NavLink>
      </div>
    </div>
  );
};

export default ProductsCartWithVideo;
