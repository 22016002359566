import React from 'react';
import './Banner.css';
import { motion } from 'framer-motion';
import { ReactComponent as BannerSVG } from '../../../assets/image/bannerfortop.svg'; // Імпортуємо SVG як компонент

const Banner = ({
  direction = 'left',
  text = '',
  href = '',
  className = '',
  useSvg = false,
}) => {
  const initialPosition = direction === 'left' ? '100%' : '-100%';
  const animatePosition = direction === 'left' ? '-100%' : '100%';

  return (
    <motion.div
      className={`banner-content ${className}`}
      initial={{ x: initialPosition }}
      animate={{ x: animatePosition }}
      transition={{ repeat: Infinity, duration: 700, ease: 'linear' }}
    >
      {[...Array(30)].map((_, index) => (
        <a key={index} href={href} className={className}>
          {useSvg ? <BannerSVG /> : text}
        </a>
      ))}
    </motion.div>
  );
};

export default Banner;
