import React, { useState, useEffect } from 'react';
import './Footer.css';
import { motion } from 'framer-motion';
import socialIcons from '../../assets/data/socialIcons';
import Banner from '../UI/Banner/Banner';
import Conections from '../Conections/Conections';
import EffectHover from '../EffectHover/EffectHover';
import Arrow from '../UI/Arrow';
import bannerImg from '../../assets/image/bannerfortop.svg';

const Footer = ({ onTabChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSectionOpen, setIsSectionOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleClick = (tabId, event) => {
    event.preventDefault();
    onTabChange(tabId);
  };

  const toggleSection = () => {
    setIsSectionOpen(!isSectionOpen);
  };
  const closeSection = () => {
    setIsSectionOpen(false);
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__top">
          <div className="banner__top">
            <Banner
              direction="right"
              href=""
              className="banner-education"
              useSvg={true}
            />
          </div>
          <h2 className="footer__title">Want to contact Torch?</h2>
          <button className="btn-touch" onClick={toggleSection}>
            Get in touch
            <Arrow className="btn-touch__icon" />
          </button>
          <div className="footer__socials">
            <ul className="footer__list">
              {socialIcons.map((icon, index) => (
                <motion.li
                  key={index}
                  whileHover={{ background: 'black' }}
                  transition={{ duration: 800 }}
                  className="footer__social"
                >
                  <a
                    href={icon.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__link"
                  >
                    <img src={icon.src} alt={icon.alt} className="hero-icons" />
                  </a>
                </motion.li>
              ))}
            </ul>
          </div>
        </div>

        <div className="footer__wrapper">
          <div className="footer__products our-products">
            <ul className="our-products__list">
              <li className="our-products__item">
                <p>Our products</p>
              </li>
              <li className="our-products__item">
                <a href="/inflatable-dome" className="our-products__link">
                  Inflatable Planetarium
                </a>
              </li>
              <li className="our-products__item">
                <a href="/air-bubble-domes" className="our-products__link">
                  Air Bubble Dome
                </a>
              </li>
              <li className="our-products__item">
                <a href="/geodesic-domes" className="our-products__link">
                  Geodesic Dome
                </a>
              </li>
              <li className="our-products__item">
                <a href="/sota-dome" className="our-products__link">
                  Sota Dome
                </a>
              </li>
              <li className="our-products__item">
                <a
                  href="/projection-dome-system"
                  className="our-products__link"
                >
                  Projection system
                </a>
              </li>
              <li className="our-products__item">
                <a href="/domes-for-rent" className="our-products__link">
                  Rent a dome
                </a>
              </li>
              <li className="our-products__item">
                <a href="/" className="our-products__link">
                  Digital Upgrades of Planetarium
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__sizes sizes">
            <ul className="sizes__list">
              <li className="our-products__item">
                <p>Dome sizes</p>
              </li>
              <li className="our-products__item">
                <a href="/geodesic-domes?tab=5m" className="our-products__link">
                  5m dome
                </a>
              </li>
              <li className="our-products__item">
                <a href="/geodesic-domes?tab=6m" className="our-products__link">
                  6m dome
                </a>
              </li>
              <li className="our-products__item">
                <a href="/geodesic-domes?tab=7m" className="our-products__link">
                  7m dome
                </a>
              </li>
              <li className="our-products__item">
                <a
                  href="/geodesic-domes?tab=10m"
                  className="our-products__link"
                >
                  10m dome
                </a>
              </li>
              <li className="our-products__item">
                <a
                  href="/geodesic-domes?tab=12m"
                  className="our-products__link"
                >
                  12m dome
                </a>
              </li>
              <li className="our-products__item">
                <a
                  href="/geodesic-domes?tab=16m"
                  className="our-products__link"
                >
                  16m dome
                </a>
              </li>
              <li className="our-products__item">
                <a
                  href="/geodesic-domes?tab=30m"
                  className="our-products__link"
                >
                  30m dome
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__contacts contscts-us">
            <ul className="sizes__list">
              <li className="our-products__item">
                <p>Contact us</p>
              </li>
              <li className="our-products__item">
                <a href="+38 (063) 397 19 99" className="contscts-us__link">
                  Tel: +38 (063) 397 19 99
                </a>
              </li>
              <li className="our-products__item">
                <a href="" className="contscts-us__link">
                  WhatsApp: +38 (097) 350 01 01
                </a>
              </li>
              <li className="our-products__item">
                <a href="" className="contscts-us__link">
                  E-mail: sales@myplanetarium.com
                </a>
              </li>
              <li className="our-products__item">
                <a href="" className="contscts-us__link">
                  Skype: viki.niki8
                </a>
              </li>
            </ul>
          </div>
          <button className="back-to-top" onClick={scrollToTop}>
            Back to top
          </button>
        </div>
        <div className="banner__email">
          <Banner
            direction="left"
            text="sales@myplanetarium.com"
            href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@myplanetarium.com"
            className="banner-email"
          />
        </div>

        <ul className="footer__bottom">
          <li className="our-products__item-bottom">
            <p className="our-products__link">
              2024 © ASE. All rights reserved
            </p>
          </li>
          <li className="our-products__item-bottom">
            <a href="/" className="our-products__link">
              Privacy Policy
            </a>
          </li>
          <li className="our-products__item-bottom">
            <a href="/" className="our-products__link">
              Terms And Conditions
            </a>
          </li>
          <li className="our-products__item-bottom">
            <a href="https://www.dreamext.com/" className="our-products__link">
              Website by Dreamext
            </a>
          </li>
        </ul>
      </div>

      {isSectionOpen && <Conections closeSection={closeSection} />}
    </footer>
  );
};

export default Footer;
