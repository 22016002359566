import React, { useEffect, useState } from 'react';
import './SliderEffects.css';
import SliderImg1 from '../../../assets/image/slider-img1.jpg';
import SliderImg2 from '../../../assets/image/slider-img2.jpg';
import SliderImg3 from '../../../assets/image/slider-img3.jpg';

const swiperSlide = [
  {
    id: 1,
    imgSrc: SliderImg1,
    name: 'For restaurants',
  },
  {
    id: 2,
    imgSrc: SliderImg2,
    name: 'For exhibitions',
  },
  {
    id: 3,
    imgSrc: SliderImg3,
    name: 'For recreation',
  },
];

const SliderEffects = () => {
  const duration = 6000; // Duration for each slide
  const [slides, setSlides] = useState(swiperSlide); // State for slides

  useEffect(() => {
    const interval = setInterval(() => {
      // Rotate slides: move the first slide to the end
      setSlides((prevSlides) => {
        const newSlides = [...prevSlides];
        const firstSlide = newSlides.shift();
        newSlides.push(firstSlide);
        return newSlides;
      });
    }, duration); // Delay between slide transitions

    return () => clearInterval(interval); // Clear interval on unmount
  }, [duration]);

  useEffect(() => {
    const activeTimer = document.querySelector(`.timer[data-slide="0"] span`);

    if (activeTimer) {
      activeTimer.style.width = '100%';
      activeTimer.style.transition = 'none';

      setTimeout(() => {
        activeTimer.style.transition = `width ${duration}ms linear infinite`; // Infinite animation
        activeTimer.style.width = '0%';
      }, 0);
    }
  }, [duration]); // Таймер працює постійно

  return (
    <div className="slider-container">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide-item ${index === 0 ? 'active' : 'inactive'}`} // Apply "active" only for the first slide
        >
          <img src={slide.imgSrc} alt={slide.name} className="slide-image" />
          <span className="slide-text">{slide.name}</span>

          <div
            className={`timer ${index === 0 ? 'active' : 'inactive'}`}
            data-slide={index}
          >
            <span></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderEffects;
