import React, { useState, useEffect, useRef } from 'react';
import './SliderOfProjects.css';
import sliderImg1 from '../../assets/image/geodesic-domes/sliderOfProject-img1.jpg';
import sliderImg2 from '../../assets/image/geodesic-domes/sliderOfProject-img2.jpg';
import sliderImg3 from '../../assets/image/geodesic-domes/sliderOfProject-img3.jpg';
import sliderImg4 from '../../assets/image/geodesic-domes/sliderOfProject-img4.jpg';

const slides = [
  { id: 1, image: sliderImg1 },
  { id: 2, image: sliderImg2 },
  { id: 3, image: sliderImg3 },
  { id: 4, image: sliderImg4 },
  { id: 5, image: sliderImg3 },
  { id: 6, image: sliderImg4 },
];

const SliderOfProjects = ({ isOpen, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef(0);
  const autoSlideRef = useRef();

  const changeSlide = (direction) => {
    setCurrentSlide((prev) => {
      const totalSlides = slides.length;
      const newIndex = (prev + direction + totalSlides) % totalSlides;
      return newIndex;
    });
  };

  useEffect(() => {
    autoSlideRef.current = setInterval(() => {
      changeSlide(1);
    }, 3000);
    return () => clearInterval(autoSlideRef.current);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
    };
    if (isOpen) window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const touchDifference = touchStartX.current - touchEndX;
    if (Math.abs(touchDifference) > 50)
      changeSlide(touchDifference > 0 ? 1 : -1);
  };

  const visibleSlides = getVisibleSlides();

  return (
    <div className="custom-slider-overlay" onClick={onClose}>
      <div
        className="custom-slider-container"
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <button
          className="custom-slider-button custom-prev"
          onClick={() => changeSlide(-1)}
        >
          <span>&#10094;</span>
        </button>
        <div
          className="custom-slider-images"
          style={{
            transform: `translateX(-${currentSlide * (100 / visibleSlides)}%)`,
            transition: 'transform 0.5s ease',
            width: `${(100 / visibleSlides) * slides.length}%`,
            gap: '20px',
          }}
        >
          {slides.map((slide) => (
            <div
              key={slide.id}
              className="slider-image-wrapper"
              style={{ flex: `0 0 calc(${100 / visibleSlides}% - 20px)` }}
            >
              <img
                src={slide.image}
                alt={`Slide ${slide.id}`}
                className="custom-slider-image"
              />
            </div>
          ))}
        </div>
        <button
          className="custom-slider-button custom-next"
          onClick={() => changeSlide(1)}
        >
          <span>&#10095;</span>
        </button>
        <div className="custom-pagination">
          {slides.map((slide, index) => (
            <button
              key={slide.id}
              className={`custom-pagination-dot ${
                currentSlide === index ? 'active' : ''
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
        <button className="close-btn custom-close-slider" onClick={onClose}>
          <span className="close-btn__icon"></span>
        </button>
      </div>
    </div>
  );
};

const getVisibleSlides = () => {
  if (window.innerWidth <= 360) return 1;
  if (window.innerWidth <= 490) return 2;
  if (window.innerWidth <= 660) return 3;
  if (window.innerWidth <= 790) return 4;
  if (window.innerWidth <= 1000) return 3;
  if (window.innerWidth <= 1300) return 4;
  return 5;
};

export default SliderOfProjects;
