// MobilePlanetariumProduct.js
import React from 'react';
import mobilePlanetariumData from '../../../assets/data/mobilePlanetariumData';
import './MobilePlanetariumProduct.css';

const MobilePlanetariumProduct = ({ id, image, text }) => {
  return (
    <div className="mobile-planetarium-card">
      <img
        src={image}
        alt={`Planetarium ${id}`}
        className="mobile-planetarium-image"
      />
      <p className="mobile-planetarium-text">{text}</p>
    </div>
  );
};

export default MobilePlanetariumProduct;
