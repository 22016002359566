import React, { useEffect, useState } from 'react';
import './Conections.css';

const contactsData = [
  {
    text: 'Phone number',
    data: '+38 (063) 397 19 99',
  },
  {
    text: 'WhatsApp',
    data: '+38 (097) 350 01 01',
  },
  {
    text: 'E-mail address',
    data: 'sales@myplanetarium.com',
  },

  {
    text: 'Skype',
    data: 'viki.niki8',
  },
];

const Conections = ({ closeSection }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(closeSection, 300);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    } else if (!/^[a-zA-Z\s]{2,}$/.test(formValues.fullName)) {
      errors.fullName = 'Full Name must contain only letters and spaces';
    }

    if (!formValues.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (
      !/^\+?38\s?\(?0\d{2}\)?\s?\d{3}\s?\d{2}\s?\d{2}$/.test(
        formValues.phoneNumber
      )
    ) {
      errors.phoneNumber =
        'Phone number format is invalid (e.g., +38 (063) 397 19 99)';
    }

    if (!formValues.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formValues.message.trim()) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitted(true);
      console.log('Form submitted successfully:', formValues);

      setFormValues({
        fullName: '',
        phoneNumber: '',
        email: '',
        message: '',
      });
      setFormErrors({});
    }
  };

  return (
    <section className={`conections__overlay ${isVisible ? 'active' : ''}`}>
      <div className="conections__wrapper">
        <div className="conections__container container">
          <button
            className="close-btn close-btn-for-contecions"
            onClick={handleClose}
          >
            <span className="close-btn__icon"></span>
          </button>
          <div className="conection__content">
            <div className="conection__content-left con-left">
              <h2 className="con-left__title">
                Want to talk to us? <br /> <span> Get in touch.</span>
              </h2>
              <div className="con-left__contacts">
                <ul className="con-left__list">
                  {contactsData.map((item, index) => (
                    <li className="con-left__item" key={index}>
                      <div className="con-left__text">{item.text}</div>
                      <span className="con-left__data">{item.data}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="conection__content-right con-right">
              <div className="con-right__name">
                Please fill in the form below. We aim to reply within 1 business
                day.
              </div>
              <form className="con-right__form" onSubmit={handleSubmit}>
                {formErrors.fullName && (
                  <p className="con__error-message">{formErrors.fullName}</p>
                )}
                <input
                  type="text"
                  className={`con-right__input ${
                    formErrors.fullName ? 'error' : ''
                  }`}
                  placeholder="Full Name"
                  name="fullName"
                  value={formValues.fullName}
                  onChange={handleInputChange}
                />

                {formErrors.phoneNumber && (
                  <p className="con__error-message">{formErrors.phoneNumber}</p>
                )}
                <input
                  type="text"
                  className={`con-right__input ${
                    formErrors.phoneNumber ? 'error' : ''
                  }`}
                  placeholder="Phone number"
                  name="phoneNumber"
                  value={formValues.phoneNumber}
                  onChange={handleInputChange}
                />

                {formErrors.email && (
                  <p className="con__error-message">{formErrors.email}</p>
                )}
                <input
                  type="email"
                  className={`con-right__input ${
                    formErrors.email ? 'error' : ''
                  }`}
                  placeholder="Enter your email"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />

                {formErrors.message && (
                  <p className="con__error-message">{formErrors.message}</p>
                )}
                <textarea
                  className={`con-right__textarea ${
                    formErrors.message ? 'error' : ''
                  }`}
                  placeholder="Your Message"
                  name="message"
                  value={formValues.message}
                  onChange={handleInputChange}
                ></textarea>

                <div className="con-right__checkbox-wrapper">
                  <input type="checkbox" className="con-right__checkbox" />
                  <span>
                    Would you like to receive updates from us? (We promise no
                    spam!)
                  </span>
                </div>
                <button className="con-right__submit" type="submit">
                  Submit Message
                </button>
                {isSubmitted && (
                  <p className="success-message">
                    Form submitted successfully!
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conections;
