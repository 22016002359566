import React from 'react';
import Header from '../components/Header/Header';
import UpcomingEvent from '../components/UpcomingEvent/UpcomingEvent';
import DomesImg1 from '../assets/image/domes-for-rent/domes-img1.jpg';
import DomesImg2 from '../assets/image/domes-for-rent/domes-img2.jpg';
import DomesImg3 from '../assets/image/domes-for-rent/domes-img3.jpg';
import DomesImg4 from '../assets/image/domes-for-rent/domes-img4.jpg';
import DomesImg5 from '../assets/image/domes-for-rent/domes-img5.jpg';
import DomesImg6 from '../assets/image/domes-for-rent/domes-img6.jpg';
import AnimatedCard from '../components/UI/AnimatedCard';
import Title from '../components/UI/Title/Title';
import ScrollText from '../components/UI/ScrollText/ScrollText';

const ProjectionDomeRental = () => {
  return (
    <>
      <Header activePage="domes-for-rent" />
      <section className="projection-dome-rental">
        <div className="projection-dome-rental__container container">
          <span className="ellipse1"></span>
          <span className="ellipse2"></span>
          <span className="ellipse3"></span>
          <span className="ellipse4"></span>
          <span className="ellipse5"></span>
          <span className="ellipse6"></span>
          <span className="ellipse7"></span>
          <span className="ellipse8"></span>
          <Title
            text=" Projection dome Rental"
            className="projection-dome-rental__title"
          />

          <h5 className="projection-dome-rental__subtitle main-title__subtitle">
            ASE domes rental - for every possible use
          </h5>
          <div className="projection-dome-rental__content">
            <p className="projection-dome-rental__text ds-text">
              ASE geodesic dome tents are guaranteed quality and proven event
              solutions. Organizing an event or looking for a glamping solution?
              <br /> <br />
              Check our offer. You can rent a geodesic dome diameter of 3 to 22
              meters. 
            </p>
            <p className="projection-dome-rental__text-small ds__text-small ">
              Domes with aluminum frames (rigit domes) are used indoors or
              outdoors as portable planetariums, exhibition halls and awnings
              for presentations, parties. All domes are light and
              quick-assembled. Installation and calibration don't take much
              time. <br /> <br />
              Domes with a steel frame are used outdoors and can withstand any
              weather conditions, strong wind, and hurricane. 
            </p>
          </div>

          <div className="projection-dome-rental__images">
            <AnimatedCard>
              <img
                src={DomesImg1}
                alt=""
                className="domes-rental__image-left pr-dome-img"
              />
            </AnimatedCard>
            <AnimatedCard>
              <img
                src={DomesImg2}
                alt=""
                className="domes-rental__image-right pr-dome-img"
              />
            </AnimatedCard>
            <AnimatedCard>
              <img
                src={DomesImg3}
                alt=""
                className="domes-rental__image-center pr-dome-img"
              />
            </AnimatedCard>
          </div>
          <div className="projection-dome-rental__details pr-dome">
            <ScrollText>
              <p className="pr-dome__text ds-text">
                The covering of the dome can
                <span>
                  be fully transparent, transparent front,  or fully opaque. You
                  can order a cover in any color.    Geodesic domes with steel
                  or aluminum frame are suitable for various indoor and outdoor
                  activities. It can be an event, festival or exhibition. The
                  dome can be used as a unique marketing tool.
                </span>
              </p>
            </ScrollText>

            <ScrollText>
              <h5 className="pr-dome__subtitle main-title__subtitle">
                Also, we will help to stylize it under needs and your brand.
              </h5>
            </ScrollText>
            <p className="pr-dome__text ds-text">
              <ScrollText>
                <span>
                  You can order a dome branded under your trademark. We develop
                  a 3D model of your dome, with a choice of dome color, logo
                  size creating a unique powerful sales tool.
                </span>
              </ScrollText>
            </p>
          </div>
          <div className="projection-dome-rental__images pr-dome-images">
            <AnimatedCard>
              <img
                src={DomesImg4}
                alt=""
                className="pr-dome-image__left pr-dome-img"
              />
            </AnimatedCard>
            <AnimatedCard>
              <img
                src={DomesImg5}
                alt=""
                className="pr-dome-image__right pr-dome-img"
              />
            </AnimatedCard>
            <AnimatedCard>
              <img
                src={DomesImg6}
                alt=""
                className="pr-dome-image__center pr-dome-img"
              />
            </AnimatedCard>
          </div>
        </div>
      </section>
      <UpcomingEvent />
    </>
  );
};

export default ProjectionDomeRental;
