import React from 'react';
import Header from '../components/Header/Header';
import ProductsCart from '../components/UI/ProductsCart/ProductsCart';
import Title from '../components/UI/Title/Title';
import ProductsCartWithVideo from '../components/UI/ProductCartWithVideo/ProductCartWithVideo';

const Planetarium = () => {
  return (
    <>
      <Header activePage="/planetarium-software" />
      <section className="planetarium">
        <div className="planetarium__container container">
          <Title text="Planetarium Software" className="planetarium__title" />
          <div className="planetarium__rows">
            <ProductsCartWithVideo
              title="Free soft for planetarium"
              subtitle="Stellarium - free soft for planetarium"
              description="Stellarium is software that calculates the positions of the Sun and Moon, planets and stars, and draws how the sky would look to an observer depending on their location and the time."
              buttonText="Learn more"
              videoUrl="https://www.youtube.com/embed/hYQABEZSgxg" // Відео YouTube
              to="/stellarium"
              titleClass="custom-title-class"
              subtitleClass="custom-subtitle-class"
              descriptionClass="custom-description-class"
              buttonClass="custom-button-class"
              videoClass="custom-video-class"
            />
            <ProductsCartWithVideo
              title="Professional software for planetarium"
              subtitle="Shira Softwares"
              description="Shira Dome Console new version, which works integrated with Shira Presenter, has been updated with the following features. With this development, it is possible to create and save any content shown on the presenter screen as 4K image frames. You can convert the created image sequences to video file formats with Shira Movie Encoder or other third-party software."
              buttonText="Learn more"
              videoUrl="https://www.youtube.com/embed/kYRGWkaSzy4" // Вбудований URL для відео
              to="/shira-softwares"
              titleClass="custom-title-class"
              subtitleClass="custom-subtitle-class"
              descriptionClass="custom-description-class"
              buttonClass="custom-button-class"
              videoClass="custom-video-class"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Planetarium;
