import React from 'react';
import Header from '../components/Header/Header';
import UpcomingEvent from '../components/UpcomingEvent/UpcomingEvent';
import Button from '../components/UI/Button/Button';
import AirImage1 from '../assets/image/air-bubble-domes/air-image1.jpg';
import AirImage2 from '../assets/image/air-bubble-domes/air-image2.jpg';
import AirImage3 from '../assets/image/air-bubble-domes/air-image3.jpg';
import AirImage4 from '../assets/image/air-bubble-domes/air-image4.jpg';
import AirImage5 from '../assets/image/air-bubble-domes/air-image5.jpg';
import Title from '../components/UI/Title/Title';
import ScrollText from '../components/UI/ScrollText/ScrollText';
import AnimatedText from '../components/UI/AnimatedText';

const AirBubbleDomes = () => {
  return (
    <>
      <Header activePage="/planetarium/air-bubble-domes" />
      <section className="air-bubble-domes">
        <div className="air-bubble-domes__container container">
          <Title text="Air Bubble Domes" className="air-bubble-domes__title" />
          <div className="air-bubble-domes__content air-content">
            <div className="air-content__right">
              <h4 className="air-content__subtitle">
                Bubble Inflatable Dome is designed for indoor and outdoor use.
              </h4>
            </div>
            <AnimatedText>
              <p className="air-content__left">
                The stable constructions of an unusual form are a <br /> novelty
                in the market of inflatable frameless dome tents. <br /> <br />
                You can order an inflatable tent with any print on the <br />
                outer or inner surface of the dome.
                <br /> <br />
                The bubble dome can be used for entertainment or marketing
                events.
                <br /> <br />
                You can also use the dome as a planetarium and a spherical movie
                theater.
              </p>
            </AnimatedText>
            <div className="air-content__bottom">
              <p className="air-content__textforbutton">
                If you have any questions, feel free to email us.
              </p>
              <Button
                to="https://mail.google.com/mail/u/0/?fs=1&to=sales@myplanetarium.com&tf=cm"
                buttonText="Write a letter"
                className="air-content__btn"
              />
            </div>
          </div>
          <div className="air-bubble-domes__images">
            <AnimatedText>
              <img
                src={AirImage1}
                alt=""
                className="air-bubble-domes__img air-bubble-domes__img--small"
              />
            </AnimatedText>

            <AnimatedText>
              <img
                src={AirImage2}
                alt=""
                className="air-bubble-domes__img air-bubble-domes__img--medium"
              />
            </AnimatedText>

            <AnimatedText>
              <img
                src={AirImage3}
                alt=""
                className="air-bubble-domes__img air-bubble-domes__img--large"
              />
            </AnimatedText>
            <AnimatedText>
              {' '}
              <img
                src={AirImage4}
                alt=""
                className="air-bubble-domes__img  air-bubble-domes__img--extra-medium"
              />
            </AnimatedText>

            <AnimatedText>
              {' '}
              <img
                src={AirImage5}
                alt=""
                className="air-bubble-domes__img air-bubble-domes__img--big"
              />
            </AnimatedText>
          </div>
        </div>
        <UpcomingEvent />
      </section>
    </>
  );
};

export default AirBubbleDomes;
