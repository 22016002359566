import ProfileOfCustomer1 from '../image/reviews-section/profileOfCustomer1.png';
import ProfileOfCustomer2 from '../image/reviews-section/profileOfCustomer2.png';
import ProfileOfCustomer3 from '../image/reviews-section/profileOfCustomer3.png';
import ProfileOfCustomer4 from '../image/reviews-section/profileOfCustomer4.png';
import ProfileOfCustomer5 from '../image/reviews-section/profileOfCustomer5.png';
import ProfileOfCustomer6 from '../image/reviews-section/profileOfCustomer6.png';

const reviewsOfCustomers = [
  {
    id: 1,
    imgSrc: ProfileOfCustomer1,
    userName: 'Yury Kovalchuk',
    category: 'Inflatable Dome',
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    path: '/inflatable-dome', // Added path
  },
  {
    id: 2,
    imgSrc: ProfileOfCustomer2,
    userName: 'Kateryna Yalovenko',
    category: 'Air bubble Domes',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
    path: '/planetarium/air-bubble-domes', // Added path
  },
  {
    id: 3,
    imgSrc: ProfileOfCustomer3,
    userName: 'Nataliya Poleno',
    category: 'Sota Dome',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
    path: '/sota-dome', // Added path
  },
  {
    id: 4,
    imgSrc: ProfileOfCustomer4,
    userName: 'Andriy Gerasimenko',
    category: 'Inflatable Dome',
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    path: '/inflatable-dome', // Added path
  },
  {
    id: 5,
    imgSrc: ProfileOfCustomer5,
    userName: 'Valentyna Kvashenko',
    category: 'Inflatable Dome',
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    path: '/inflatable-dome', // Added path
  },
  {
    id: 6,
    imgSrc: ProfileOfCustomer6,
    userName: 'Pavlo Aksyonov',
    category: 'Geodesic domes',
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
    path: '/geodesic-domes', // Added path
  },
];

export default reviewsOfCustomers;
