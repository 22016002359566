import React from 'react';
import './TableComponentMobile.css'; // Підключаємо стилі

const domeData = [
  {
    diameter: '5m',
    height: '2.99m',
    surfaceArea: '19.6m²',
    standing: '25',
    sitting: '15',
  },
  {
    diameter: '6m',
    height: '3.59m',
    surfaceArea: '28m²',
    standing: '40',
    sitting: '25',
  },
  {
    diameter: '7m',
    height: '4.0m',
    surfaceArea: '38m²',
    standing: '45',
    sitting: '32',
  },
  {
    diameter: '8m',
    height: '4.19m',
    surfaceArea: '50m²',
    standing: '55',
    sitting: '35',
  },
  {
    diameter: '10m',
    height: '5.0m',
    surfaceArea: '78m²',
    standing: '100',
    sitting: '48',
  },
  {
    diameter: '12m',
    height: '6.0m',
    surfaceArea: '113m²',
    standing: '140',
    sitting: '79',
  },
  {
    diameter: '14m',
    height: '6.25m',
    surfaceArea: '153m²',
    standing: '190',
    sitting: '103',
  },
  {
    diameter: '16m',
    height: '7.2m',
    surfaceArea: '200m²',
    standing: '220',
    sitting: '110',
  },
  {
    diameter: '21m',
    height: '10.5m',
    surfaceArea: '346m²',
    standing: '420',
    sitting: '215',
  },
  {
    diameter: '30m',
    height: '15m',
    surfaceArea: '706m²',
    standing: '1400',
    sitting: '790',
  },
];

const TableComponentMobile = ({ visible, toggleVisibility }) => {
  return (
    <div className={`table-mobile-section ${visible ? 'show' : ''}`}>
      <div className="table-mobile__container">
        <div className="table-mobile-section__top">
          <h2 className="table-mobile-title">
            Approximate capacity of the domes
          </h2>
          <button
            className="close-btn close-mobile-btn"
            onClick={toggleVisibility}
          >
            <span className="close-btn__icon"></span>
          </button>
        </div>

        <div className="dome-cards-mobile">
          {domeData.map((dome, index) => (
            <div key={index} className="dome-card-mobile">
              <div className="dome-info-mobile">
                <strong>Base Diameter:</strong> {dome.diameter}
              </div>
              <div className="dome-info-mobile">
                <strong>Height:</strong> {dome.height}
              </div>
              <div className="dome-info-mobile">
                <strong>Surface Area:</strong> {dome.surfaceArea}
              </div>
              <div className="dome-info-mobile">
                <strong>People standing (max):</strong> {dome.standing}
              </div>
              <div className="dome-info-mobile">
                <strong>People sitting (max):</strong> {dome.sitting}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableComponentMobile;
