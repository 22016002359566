import ImageProjects1 from '../image/our-projects/image-projects1.jpg';
import ImageProjects2 from '../image/our-projects/image-projects2.jpg';
import ImageProjects3 from '../image/our-projects/image-projects3.jpg';
import ImageProjects4 from '../image/our-projects/image-projects4.jpg';
import ImageProjects5 from '../image/our-projects/image-projects5.jpg';
import ImageProjects6 from '../image/our-projects/image-projects6.jpg';
import ImageProjects7 from '../image/our-projects/image-projects7.jpg';
import ImageProjects8 from '../image/our-projects/image-projects8.jpg';
const cardData = [
  {
    id: '1',
    year: 'June 2019',
    title: '6m Open dome for Wine Museum Bordeaux, France',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects1, // Замініть посилання на зображення своїм
  },
  {
    id: 2,
    year: 'July 2017',
    title: '7m negative pressure dome Dubai',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects2,
  },
  {
    id: 3,
    year: 'January 2020',
    title: '3D theatre Sphero 10m projection dome, Georgia, Tbilisi',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects3,
  },
  {
    id: 4,
    year: 'March 2020',
    title: '12m State Planetarium Oman, Sohar',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects4,
  },
  {
    id: 5,
    year: 'March 2018',
    title: '14m restaurant dome Lebanon, Beirut',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects5,
  },
  {
    id: 6,
    year: 'April 2017',
    title: '7m negative-pressure dome Dubai',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects6,
  },
  {
    id: 7,
    year: 'June 2019',
    title: '6m negative-pressure dome for Nokia, Mecca, Saudi Arabia',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects7,
  },
  {
    id: 8,
    year: 'September 2018',
    title: '12 m event dome Kiev',
    description: 'Development of a conceptual proposal for the EVENTagency.',
    image: ImageProjects8,
  },
];

export default cardData;
