import React from 'react';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import DomeModels from '../components/DomeModels/DomeModels';
import ImmersiveProjection from '../components/ImmersiveProjection/ImmersiveProjection';
import ProjectionSystems from '../components/ProjectionSystems/ProjectionSystems';
import ReviewsOfCustomers from '../components/ReviewsOfCustomers/ReviewsOfCustomers';

const Home = () => {
  return (
    <div className="home" activePage="/planetarium">
      <Header color="white" />
      <Hero />
      <DomeModels />
      <ImmersiveProjection />
      <ProjectionSystems />
      <ReviewsOfCustomers />
    </div>
  );
};

export default Home;
