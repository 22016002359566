import React from 'react';
import { NavLink } from 'react-router-dom';
import './Button.css';

const Button = ({ to, buttonText, className }) => {
  return (
    <NavLink to={to} className={`universal-btn ${className}`}>
      {buttonText}
    </NavLink>
  );
};

export default Button;
