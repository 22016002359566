import React from 'react';
import Header from '../components/Header/Header';
import ProjectsCard from '../components/UI/ProjectsCard/ProjectsCard';
import cardData from '../assets/data/Projects';
import Title from '../components/UI/Title/Title';

const OurProjects = () => {
  const oddCards = cardData.filter((card) => card.id % 2 !== 0); // Непарні id
  const evenCards = cardData.filter((card) => card.id % 2 === 0); // Парні id

  return (
    <>
      <Header activePage="/our-projects" />
      <section className="our-projects">
        <div className="our-projects__container container">
          <Title text="Our projects" className="our-projects__title" />
          <div className="our-projects__wrapper">
            <div className="our-projects__column">
              {oddCards.map((card, index) => (
                <ProjectsCard
                  key={index}
                  year={card.year}
                  title={card.title}
                  description={card.description}
                  image={card.image}
                />
              ))}
            </div>

            <div className="our-projects__column our-projects__column--right">
              {evenCards.map((card, index) => (
                <ProjectsCard
                  key={index}
                  year={card.year}
                  title={card.title}
                  description={card.description}
                  image={card.image}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurProjects;
