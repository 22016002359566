import React, { useState, useEffect } from 'react';
import './EffectHover.css';
import { NavLink, useLocation } from 'react-router-dom';
import Arrow from '../UI/Arrow';
import { defaultNavLinks, othersNavLinks } from '../../assets/data/navLink';

const EffectHover = ({ toggleSection }) => {
  const [navLinks, setNavLinks] = useState(defaultNavLinks);
  const location = useLocation();

  useEffect(() => {
    const isOthersNav = othersNavLinks.some(
      (link) => link.path === location.pathname
    );
    if (isOthersNav) {
      setNavLinks(defaultNavLinks);
    } else {
      setNavLinks(othersNavLinks);
    }
  }, [location.pathname]);

  return (
    <div className="dropdown-menu">
      <div className="menu-hover__touch">
        <p>Want to contact Torch?</p>
        <button className="btn__getintouch" onClick={toggleSection}>
          Get in touch
        </button>
      </div>
      <div className="menu-hover__left">
        <ul className="menu-hover__domes domes">
          {navLinks.map((link, index) => (
            <li className="domes__item" key={index}>
              <Arrow className="domes__arrow" />
              <NavLink
                to={link.path}
                className="domes__link"
                activeClassName="domes__link--active"
              >
                {link.display}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EffectHover;
