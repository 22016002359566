import React from 'react';
import './DomeModels.css';
import data from '../../assets/data/imageCardsData';
import MiddleImg1 from '../../assets/image/dome-models/middle-img1.jpg';
import MiddleImg2 from '../../assets/image/dome-models/middle-img2.jpg';
import MiddleImg3 from '../../assets/image/dome-models/middle-img3.jpg';
import MiddleImg4 from '../../assets/image/dome-models/middle-img4.jpg';
import SmalImg1 from '../../assets/image/dome-models/smal-img1.jpg';
import SmalImg2 from '../../assets/image/dome-models/smal-img2.jpg';
import SmalImg3 from '../../assets/image/dome-models/smal-img3.jpg';
import ImageCard from '../UI/ImageCard/ImageCard';

import AnimatedText from '../UI/AnimatedText';
import AnimatedCard from '../UI/AnimatedCard';
import StarAnimation from '../UI/StarAnimation/StarAnimation';

const DomeModels = () => {
  return (
    <section className="dome-models dome">
      <div className="dome__container container">
        <StarAnimation>
          <div className="dome__wrapper">
            {data.map((card) => (
              <ImageCard
                key={card.id}
                imgSrc={card.imgSrc}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            ))}
            <img
              src={SmalImg1}
              alt=""
              className="dome__img--smal smal-img-left"
            />
            <img
              src={SmalImg2}
              alt=""
              className="dome__img--smal smal-img-top"
            />
            <img
              src={SmalImg3}
              alt=""
              className="dome__img--smal smal-img-right"
            />
            <img
              src={MiddleImg1}
              alt=""
              className="dome__img--middle middle-top"
            />
            <img
              src={MiddleImg2}
              alt=""
              className="dome__img--middle middle-center"
            />
            <img
              src={MiddleImg3}
              alt=""
              className="dome__img--middle middle-right"
            />
            <img
              src={MiddleImg4}
              alt=""
              className="dome__img--middle middle-bottom"
            />

            <div className="dome__content">
              <AnimatedText>
                <h2 className="dome__title">Dome Models</h2>
                <h4 className="dome__description un-dome__title">
                  Portable inflatable planetarium for indoor use <br /> for
                  schools and educational institutions
                </h4>
              </AnimatedText>
            </div>
          </div>
        </StarAnimation>
      </div>
    </section>
  );
};

export default DomeModels;
