import React from 'react';
import Header from '../components/Header/Header';

import { motion } from 'framer-motion';
import socialIcons from '../assets/data/socialIcons';
import contactsImg from '../assets/image/contacts/contacts-image.jpg';
import UpcomingEvent from '../components/UpcomingEvent/UpcomingEvent';
import AnimatedText from '../components/UI/AnimatedText';
import AnimatedCard from '../components/UI/AnimatedCard';
import Title from '../components/UI/Title/Title';

const Contacts = () => {
  return (
    <>
      <Header activePage="contacts" />
      <section className="contacts">
        <div className="contacts__container container">
          <Title text="Contacts" className="contacts__title" />

          <div className="contacts__top">
            <AnimatedText>
              <div className="contacts__content">
                <h5 className="main-title__subtitle">Company information</h5>
                <p className="contacts-text ds-text">
                  ASE has a zero discrimination policy and collaborates with our
                  partners all over the world to bring customers directly to
                  their door.
                </p>
                <p className="contacts-text ds-text">
                  If you wish to experience the freedom and exhilaration of
                  running your own successful business or are simply looking to
                  purchase for your own business please do not hesitate to
                  contact us.
                </p>
              </div>
            </AnimatedText>

            <div className="contacts-image">
              <AnimatedCard>
                <img src={contactsImg} alt="Contact" />
              </AnimatedCard>
            </div>
          </div>
          <div className="contacts__bottom">
            <div className="contacts__bottom--left">
              <AnimatedText>
                <p>
                  ASE LLC Company No. 39317022 <br />
                  Danyla Halytskogo street, 29/7, Vinnytsia, <br /> Ukraine,
                  21000
                </p>
              </AnimatedText>

              <div className="contacts__socials">
                <ul className="footer__list">
                  {socialIcons.map((icon, index) => (
                    <motion.li
                      key={index}
                      whileHover={{ background: 'black' }}
                      transition={{ duration: 800 }}
                      className="footer__social contacts__social"
                    >
                      <a href="" className="footer__link">
                        <img
                          src={icon.src}
                          alt={icon.alt}
                          className="hero-icons"
                        />
                      </a>
                    </motion.li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="contacts__bottom--right contacts-bottom">
              <AnimatedText>
                <p className="contacts-bottom__info info-subtitle">
                  tel: +380 97 350 0101 <br /> WhatsApp: +380 63 397 1999 <br />
                  sales@myplanetarium.com <br />
                  skype: viki.niki8
                </p>
              </AnimatedText>
            </div>
          </div>
        </div>
        <div className="contacts__map"></div>

        <UpcomingEvent />
      </section>
    </>
  );
};

export default Contacts;
