import Facebook from '../../assets/image/facebook.svg';
import LinkenIn from '../../assets/image/linkenin.svg';
import Pinterst from '../../assets/image/pinterst.svg';
import Youtube from '../../assets/image/youtube.svg';

const socialIcons = [
  {
    src: Facebook,
    alt: 'icon-facebook',
    href: 'https://www.facebook.com/aseplanetariummobile/',
  },
  {
    src: Youtube,
    alt: 'icon-youtube',
    href: 'https://www.youtube.com/channel/UCBZXxCuXNxQo7vQb6up3ntA',
  },
  {
    src: Pinterst,
    alt: 'icon-pinterest',
    href: 'https://www.pinterest.com/asefulldome/',
  },
  {
    src: LinkenIn,
    alt: 'icon-linkenin',
    href: 'https://www.linkedin.com/company/advanced-sensory-education/?originalSubdomain=ua',
  },
];

export default socialIcons;
