import React from 'react';
import Header from '../components/Header/Header';
import AnimatedText from '../components/UI/AnimatedText';
import Title from '../components/UI/Title/Title';
import MobilePlanetariumProduct from '../components/UI/MobilePlanetariumProduct/MobilePlanetariumProduct';
import mobilePlanetariumData from '../assets/data/mobilePlanetariumData';
import DomeSwiper from '../components/DomeSwiper/DomeSwiper';
import { slides1, slides2, slides3 } from '../assets/data/slides';
import VideoSection from '../components/VideoSection/VideoSection';
const InflatablePlanetarium = () => {
  const handleButtonClick = () => {
    console.log('Button clicked!');
  };
  return (
    <>
      <Header color="white" activePage="/planetarium/inflatable-dome" />
      <div className="inflatable-dome__page">
        <section className="inflatable-dome">
          <div className="sota-dome__container container">
            <div className="sota-dome__content">
              <AnimatedText>
                <Title
                  text="Portable Inflatable Planetarium Dome"
                  className="sota-dome__title"
                />
              </AnimatedText>
              <AnimatedText>
                <h5 className="sota-dome__subtitle main-title__subtitle">
                  In Inflatable mobile planetariums, the projection screen is
                  produced inside the twin wall sealed dome which takes the
                  desired shape through greater pressure inside the dome. This
                  design is ideal for schools and planetariums where you
                  frequently change location.
                </h5>
              </AnimatedText>
            </div>
          </div>
        </section>
        <section className="mobile-planetarium-features">
          <div className="mobile-planetarium-features__container container">
            <h4 className="mobile-planetarium-features__title un-dome__title">
              Key features of ASE Inflatable mobile planetarium
            </h4>
            <div className="mobile-planetarium-features__wrapper">
              {mobilePlanetariumData.map((item) => (
                <MobilePlanetariumProduct
                  key={item.id}
                  id={item.id}
                  image={item.image}
                  text={item.text}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="inflatable-swipers">
          <DomeSwiper
            title="Inflatable mobile planetarium in the corporate style of ASE myplantarium"
            description={
              <>
                We offer an inflatable planetarium in the original design. You
                can create a unique combination and choose colors at your
                discretion. View the gallery with bright and unusual domes and
                select your own.
                <br />
                <br />
                Light and pleasant striped design suitable for schools,
                preschool institutions, and amusement parks.
                <br />
                <br />
                Would you buy a striped portable planetarium? Share with us the
                details of the dome that you need and receive the best solution
                at the most affordable price.
              </>
            }
            slides={slides1}
          />
          <DomeSwiper
            title="Classic Inflatable mobile planetarium"
            description={
              <>
                You can buy a single-colored dome by selecting the color you
                like from the gallery or suggest your own.
                <br />
                <br />
                We offer a gallery of the most commonly used colors of portable
                planetariums - it can be black, blue, pink or any other color of
                the dome at your discretion.
                <br />
                <br />
                We recommend choosing dark fabrics for the outer shell of the
                inflatable planetarium. This will exclude the dome canopy and
                provide a high- quality image. The most popular colors are
                black, navy, and purple.
                <br />
                <br />
                Would you buy a classic portable planetarium? Share with us the
                details of the dome what you need and receive the best solution
                at the most affordable price.
              </>
            }
            slides={slides2}
          />
          <DomeSwiper
            title="Branded Inflatable mobile planetarium"
            description={
              <>
                The ASE myplanetarium company offers portable planetariums with
                a unique print on the entire surface of the dome or branding
                your logo. This is a great marketing tool, such a dome is sure
                to attract the attention of others.
                <br />
                <br />
                You can offer your drawing, idea or development and we will
                transfer it to the dome. Perhaps you have a great idea. Let's
                visualize it together! In the gallery, you can see our designs.
                <br />
                <br />
                Would you order a portable planetarium's branding? Share with us
                the details of the dome what you need and receive the best
                solution at the most affordable price.
              </>
            }
            slides={slides3}
          />
        </section>

        <VideoSection />
      </div>
    </>
  );
};

export default InflatablePlanetarium;
