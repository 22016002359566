import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const AnimatedCard = ({
  children,
  className = '',
  scaleEffect = 1.1,
  transitionDuration = 1.3,
  initialScale = 0.9,
  borderRadius = '1.25rem',
  hoverScale = 1.0,
  hoverShadow = '0 12px 24px rgba(0, 0, 0, 0.3)',
  customStyle = {},
  persistentEffect = false,
  persistentScale = [1, 1.05, 1],
  persistentDuration = 2,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    const element = ref.current;
    if (!element) return;

    const { top, bottom } = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (bottom < windowHeight && top > 0) {
      setIsVisible(true);
      window.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      ref={ref}
      initial={{ scale: initialScale, opacity: 0 }}
      animate={{
        scale: isVisible ? 1 : initialScale,
        opacity: isVisible ? 1 : 0,
        ...(persistentEffect && { scale: persistentScale }),
      }}
      transition={{
        duration: transitionDuration,
        ...(persistentEffect && {
          repeat: Infinity,
          repeatType: 'reverse',
          duration: persistentDuration,
        }),
      }}
      className={className}
      style={{
        borderRadius,
        overflow: 'hidden',
        ...customStyle,
      }}
      whileHover={{
        scale: hoverScale,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedCard;
