import React, { useEffect, useState } from 'react';
import './UpcomingEvent.css';
import ScrollText from '../UI/ScrollText/ScrollText';

const countryCodes = [
  { code: '+1', country: 'USA' },
  { code: '+44', country: 'UK' },
  { code: '+48', country: 'PL' },
  { code: '+380', country: 'UA' },
];

const countries = ['USA', 'UK', 'India', 'Ukraine', 'Canada', 'Germany'];
const products = [
  'Inflatable Dome',
  'Geodesic Domes',
  'Sota Dome',
  'Air Bubble Domes',
];

const UpcomingEvent = () => {
  const [placeholder, setPlaceholder] = useState('Area Code');
  const [isDropdownOpen, setDropdownOpen] = useState({
    areaCode: false,
    country: false,
    product: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    areaCode: '',
    country: '',
    product: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    city: '',
    comments: '',
    email: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setPlaceholder(window.innerWidth <= 768 ? 'Code' : 'Area Code');
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setDropdownOpen((prev) => ({
      areaCode: dropdown === 'areaCode' ? !prev.areaCode : false,
      country: dropdown === 'country' ? !prev.country : false,
      product: dropdown === 'product' ? !prev.product : false,
    }));
  };

  const handleSelect = (dropdown, value) => {
    setSelectedValues((prev) => ({ ...prev, [dropdown]: value }));
    setDropdownOpen((prev) => ({ ...prev, [dropdown]: false }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedValues.firstName) newErrors.firstName = true;
    if (!selectedValues.lastName) newErrors.lastName = true;
    if (!selectedValues.areaCode) newErrors.areaCode = true;
    if (!selectedValues.phoneNumber) newErrors.phoneNumber = true;
    if (!selectedValues.country) newErrors.country = true;
    if (!selectedValues.city) newErrors.city = true;
    if (!selectedValues.product) newErrors.product = true;
    if (!selectedValues.email) {
      newErrors.email = true;
    } else if (!validateEmail(selectedValues.email)) {
      newErrors.email = true;
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      console.log('Form submitted successfully:', selectedValues);
      setSelectedValues({
        areaCode: '',
        country: '',
        product: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        city: '',
        comments: '',
        email: '',
      });
      setErrors({});
    } else {
      setErrors(formErrors);
    }
  };

  const inputStyles = (field) => (errors[field] ? 'input-error' : '');

  return (
    <section className="upcoming-event">
      <div className="upcoming-event__container container">
        <div className="upcoming-event__content">
          <h1 className="upcoming-event__title">Upcoming Event</h1>
          <ScrollText>
            <h2 className="upcoming-event__subtitle info-subtitle">
              ASE Myplanetarium gives you the opportunity to rent an event dome.
              Best show of ever guarantee.
            </h2>
          </ScrollText>
          <ScrollText>
            <p className="upcoming-event__text">
              We will gladly make you an individual offer. Please fill out the
              form below.
            </p>
          </ScrollText>
        </div>
        <div className="upcoming-event__form">
          <form onSubmit={handleSubmit} className="form__best-offer">
            <div className="form-group form-group--double">
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  className={`form-input input--small ${inputStyles(
                    'firstName'
                  )}`}
                  value={selectedValues.firstName}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      firstName: e.target.value,
                    })
                  }
                  onFocus={() =>
                    setErrors((prev) => ({ ...prev, firstName: false }))
                  }
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Last Name"
                  className={`form-input form-input__lastname ${inputStyles(
                    'lastName'
                  )}`}
                  value={selectedValues.lastName}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      lastName: e.target.value,
                    })
                  }
                  onFocus={() =>
                    setErrors((prev) => ({ ...prev, lastName: false }))
                  }
                />
              </div>
            </div>

            <div className="form-group form-group--double form-inputs-numbers">
              <div className="form-input-wrapper form-input-wrapper-area">
                <input
                  type="text"
                  placeholder={placeholder}
                  value={selectedValues.areaCode}
                  className={`form-input input--small__areacode with-dropdown ${inputStyles(
                    'areaCode'
                  )}`}
                  onClick={() => handleDropdownToggle('areaCode')}
                  readOnly
                />
                <span
                  className={`dropdown-arrow dropdown-arrow-code ${
                    isDropdownOpen.areaCode ? 'active' : ''
                  }`}
                ></span>
                {isDropdownOpen.areaCode && (
                  <ul className="dropdown-list">
                    {countryCodes.map((item) => (
                      <li
                        key={item.code}
                        className="dropdown-item"
                        onClick={() => handleSelect('areaCode', item.code)}
                      >
                        {item.code} - {item.country}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className={`form-input form-input__number ${inputStyles(
                    'phoneNumber'
                  )}`}
                  value={selectedValues.phoneNumber}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      phoneNumber: e.target.value,
                    })
                  }
                  onFocus={() =>
                    setErrors((prev) => ({ ...prev, phoneNumber: false }))
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <input
                type="text"
                placeholder="Enter your email"
                className={`form-input ${inputStyles('email')}`}
                value={selectedValues.email}
                onChange={(e) =>
                  setSelectedValues({
                    ...selectedValues,
                    email: e.target.value,
                  })
                }
                onFocus={() => setErrors((prev) => ({ ...prev, email: false }))}
              />
            </div>

            <div className="form-group">
              <div className="form-input-wrapper">
                <input
                  type="text"
                  placeholder="Country"
                  value={selectedValues.country}
                  className={`form-input with-dropdown ${inputStyles(
                    'country'
                  )}`}
                  onClick={() => handleDropdownToggle('country')}
                  readOnly
                />
                <span
                  className={`dropdown-arrow ${
                    isDropdownOpen.country ? 'active' : ''
                  }`}
                ></span>
                {isDropdownOpen.country && (
                  <ul className="dropdown-list">
                    {countries.map((item) => (
                      <li
                        key={item}
                        className="dropdown-item"
                        onClick={() => handleSelect('country', item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="form-group">
              <input
                type="text"
                placeholder="City"
                className={`form-input ${inputStyles('city')}`}
                value={selectedValues.city}
                onChange={(e) =>
                  setSelectedValues({ ...selectedValues, city: e.target.value })
                }
                onFocus={() => setErrors((prev) => ({ ...prev, city: false }))}
              />
            </div>

            <div className="form-group">
              <div className="form-input-wrapper">
                <input
                  type="text"
                  placeholder="Product"
                  value={selectedValues.product}
                  className={`form-input with-dropdown ${inputStyles(
                    'product'
                  )}`}
                  onClick={() => handleDropdownToggle('product')}
                  readOnly
                />
                <span
                  className={`dropdown-arrow ${
                    isDropdownOpen.product ? 'active' : ''
                  }`}
                ></span>
                {isDropdownOpen.product && (
                  <ul className="dropdown-list">
                    {products.map((item) => (
                      <li
                        key={item}
                        className="dropdown-item"
                        onClick={() => handleSelect('product', item)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="form-group">
              <textarea
                placeholder="Comments"
                className="form-textarea"
                value={selectedValues.comments}
                onChange={(e) =>
                  setSelectedValues({
                    ...selectedValues,
                    comments: e.target.value,
                  })
                }
              ></textarea>
            </div>

            <button type="submit" className="form-button">
              Restive best offer
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvent;
