import React from 'react';
import './ProductsCart.css';
import { NavLink } from 'react-router-dom';

const ProductsCart = ({
  title,
  subtitle,
  description,
  buttonText,
  imageSrc,
  imageAlt,
  to,
  titleClass,
  subtitleClass,
  descriptionClass,
  buttonClass,
  imageClass,
  productsCartClass,
  productsCartContentClass,
}) => {
  return (
    <div className={`products-cart ${productsCartClass || ''}`}>
      <div
        className={`products-cart__content ${productsCartContentClass || ''}`}
      >
        <h6 className={`products-cart__title ${titleClass || ''}`}>{title}</h6>

        <div
          className={`products-cart__subtitle main-title__subtitle ${
            subtitleClass || ''
          }`}
        >
          {subtitle}
        </div>

        <div className={`products-cart__image ${imageClass || ''}`}>
          <img src={imageSrc} alt={imageAlt} />
        </div>

        <p
          className={`products-cart__description ds__text-small ${
            descriptionClass || ''
          }`}
        >
          {description}
        </p>

        <NavLink to={to} className={`products-cart__btn ${buttonClass || ''}`}>
          {buttonText}
        </NavLink>
      </div>
    </div>
  );
};

export default ProductsCart;
