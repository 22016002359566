import React from 'react';
import { motion } from 'framer-motion';
import i18n from '../../../i18n';
import './LanguageSwitcher.css';

const rightNavLinks = [
  { path: 'ua', display: 'UA' },
  { path: 'pl', display: 'PL' },
  { path: 'en', display: 'EN' },
];

const LanguageSwitcher = ({ activeLanguage, setActiveLanguage, className }) => {
  return (
    <ul className={`nav__right-menu right-menu ${className}`}>
      {rightNavLinks.map((item, index) => (
        <li
          className={`right-nav__item item-language ${
            activeLanguage === item.path ? 'right-nav__active' : ''
          }`}
          key={index}
        >
          <motion.button
            onClick={() => {
              i18n.changeLanguage(item.path);
              setActiveLanguage(item.path);
            }}
            animate={{
              width: activeLanguage === item.path ? 40 : 20,
              height: activeLanguage === item.path ? 40 : 20,
              borderRadius: activeLanguage === item.path ? 20 : 5,
            }}
            transition={{
              type: 'tween',
              stiffness: 200,
              damping: 20,
            }}
            className={`language-button ${
              activeLanguage === item.path ? 'btn-active' : ''
            }`}
          >
            {item.display}
          </motion.button>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSwitcher;
